import { get, merge } from 'lodash-es';

import { ELEMENT_NODE } from '@@constants/NodeTypes';

import { castArray } from './arrayUtils';

export const decodeImage = (image: HTMLImageElement) => {
    // JSDOM does not yet support `decode`, so we need to check if it is available:
    // https://github.com/jsdom/jsdom/issues/2154
    if (typeof image.decode === 'function') {
        return image.decode();
    }

    return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-param-reassign
        image.onload = resolve;
        // eslint-disable-next-line no-param-reassign
        image.onerror = reject;
    });
};

export const isFile = (value): value is File => value instanceof File;

export const getScrollContainer = (node: HTMLElement | null): HTMLElement | Window => {
    if (node == null) {
        return window;
    }

    if (node.nodeType === ELEMENT_NODE) {
        const { overflowY } = window.getComputedStyle(node);

        if (overflowY === 'auto' || overflowY === 'scroll') {
            return node;
        }
    }

    return getScrollContainer(node.parentElement);
};

export const takeFirst = (func) => {
    let runningFunc, undefinedFunc;

    return (...args) => {
        if (!runningFunc) {
            runningFunc = func(...args).finally(() => {
                runningFunc = undefinedFunc;
            });
        }

        return runningFunc;
    };
};

// If you want to merge two objects, but some paths only to a certain depth, this function is your
// friend! Pass a source and target and an array of atoms (array of regular expressions, to match the paths you
// want to not merge deeper than that).
export const atomicMerge = (target, source, atoms, path: string[] = []) => {
    if (path.length === 0) {
        merge(target, source);
    }

    const sourceAtPath = path.length ? get(source, path) : source;

    if (sourceAtPath && typeof sourceAtPath === 'object') {
        Object.keys(sourceAtPath).forEach((key) => {
            const nextPath = path.concat(key);
            const isAtomic = atoms.some((atom) => nextPath.join('.').match(atom));

            if (isAtomic) {
                Object.assign(target, {
                    [key]: get(source, nextPath),
                });
            } else {
                atomicMerge(target[key], source, atoms, nextPath);
            }
        });
    }

    return target;
};

export const updateRef = (refs, instance) => {
    castArray(refs).forEach((ref) => {
        if (typeof ref === 'function') {
            ref(instance);
        } else if (ref != null && ref.hasOwnProperty('current')) {
            // eslint-disable-next-line no-param-reassign
            ref.current = instance;
        }
    });
};
