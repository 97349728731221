import { type FilterOptionsState } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import { get, unionBy } from 'lodash-es';
import { matchSorter, type MatchSorterOptions } from 'match-sorter';
import { intersection } from 'remeda';

import { CONTENT_LANGUAGE_HEADER } from '@@api/constants/headers';
import { type TenantRouter, useTenantClient } from '@@api/services/tenant/client';
import { type Category } from '@@api/services/tenant/schemas';
import { getQueryParams } from '@@api/utils/getQueryParams';
import Autocomplete, { type AutocompleteProps } from '@@form/components/Autocomplete';
import useDeepCompareMemo from '@@hooks/useDeepCompareMemo';

import { makeCategoryTree } from './utils';

type BaseProps = Pick<
    AutocompleteProps<Category>,
    'placeholder' | 'label' | 'noOptionsText' | 'disabled' | 'required' | 'inputRef'
> & {
    excludeId?: Category['id'];
    flat?: boolean;
    params?: UnknownObject;
    allowManualValues?: boolean;
    allowUnknownValues?: boolean;
    autoSelectParents?: boolean;
};

export type CategoriesAutocompleteProps =
    | (BaseProps & {
          multiple: true;
          value: Category['id'][];
          onChange: (value: Category['id'][]) => void;
      })
    | (BaseProps & {
          multiple?: false;
          value: Category['id'] | null;
          onChange: (value: Category['id'] | null) => void;
      });

const flatten = <T extends Category>(categories: T[], result: T[] = [], level = 0) => {
    categories.forEach((category) => {
        result.push({ ...category, level });

        if (category.children) {
            flatten(category.children, result, level + 1);
        }
    });

    return result;
};

const recursiveMatch = <T extends Category>(
    array: T[],
    value: string,
    threshold: MatchSorterOptions['threshold'] = matchSorter.rankings.MATCHES,
    results: T[] = [],
) => {
    const matches = matchSorter(array, value, {
        keys: ['name'],
        sorter: (rankedItems) => rankedItems,
        threshold,
    });

    results.push(...matches);

    for (const obj of array) {
        if (obj.children && obj.children.length > 0) {
            const nextResults = recursiveMatch(obj.children, value, threshold);

            if (nextResults.length > 0) {
                results.push(obj);
            }
        }
    }

    return results;
};

const filterOptions = (options: Category[], params: FilterOptionsState<Category>) => {
    const { inputValue } = params;

    const results = recursiveMatch(options, inputValue, matchSorter.rankings.CONTAINS);

    return intersection(options, results);
};

const CategoriesAutocomplete = (props: CategoriesAutocompleteProps) => {
    const {
        autoSelectParents,
        allowManualValues,
        allowUnknownValues,
        value,
        multiple,
        excludeId,
        flat,
        params,
        onChange,
        ...rest
    } = props;
    const { client: tenantClient, queryKeys: tenantKeys } = useTenantClient();

    const memoizedParams = useDeepCompareMemo(
        () =>
            getQueryParams<TenantRouter['category']['getAll']>({
                active: null,
                sort: 'name,ASC',
                ...params,
            }),
        [params],
    );
    const headers = memoizedParams.contentLocale
        ? { [CONTENT_LANGUAGE_HEADER]: memoizedParams.contentLocale }
        : undefined;
    const { data, isLoading } = tenantClient.category.getAll.useQuery({
        queryKey: tenantKeys.category.getAll({ query: memoizedParams }),
        queryData: { query: memoizedParams, headers },
        placeholderData: keepPreviousData,
    });

    const manualOrUnknown = (value) => ({
        text: get(
            data?.body.find((category) => category.id === value),
            'name',
            value,
        ),
    });

    const allowManual = allowManualValues ? manualOrUnknown : false;
    const allowUnknown = allowUnknownValues ? manualOrUnknown : false;

    const includeIds = value?.toString();

    const valueCategoryQuery = getQueryParams<TenantRouter['category']['getAll']>({ includeIds });
    const { data: valueCategoryData, isLoading: areValueCategoriesLoading } =
        tenantClient.category.getAll.useQuery({
            queryKey: tenantKeys.category.getAll({ query: valueCategoryQuery }),
            queryData: { query: valueCategoryQuery },
            enabled: Boolean((allowManual || allowUnknown) && Boolean(includeIds)),
            placeholderData: keepPreviousData,
        });
    const valueCategories = valueCategoryData?.body || [];

    const categories = unionBy<Category>(data?.body, valueCategories, 'id');

    const filteredData = excludeId
        ? categories?.filter((category) => category.id !== excludeId)
        : categories;

    const categoryTree = flat ? filteredData : makeCategoryTree(filteredData);
    const flattenedData = flatten(categoryTree);

    const currentValue = multiple
        ? (value || [])
              .map((item) => flattenedData.find((option) => (option.id ?? option) === item))
              .filter((x): x is NonNullable<typeof x> => Boolean(x))
        : (flattenedData.find((option) => option.id === value) ?? null);

    const handleChange = (event, value, reason, details) => {
        if (
            autoSelectParents &&
            details &&
            Array.isArray(value) &&
            multiple &&
            reason === 'selectOption'
        ) {
            // Find all the parents and reverse the order (parents first)
            const matches = recursiveMatch(
                flattenedData,
                details?.option.name,
                matchSorter.rankings.EQUAL,
            ).toReversed();

            // At this point in time, the newly clicked option is already part of the value.
            // We remove it here, as it will be added back by the matches.
            // Removing it from the value and not the matches will make the order correct.
            const newValues = value.filter((val) => val.id !== details?.option.id);

            // Remove the already selected values from the matches, but keep the newly selected option
            const matchesWithoutExistingValues = matches.filter(
                (match) => !newValues.includes(match),
            );

            // Add the parents to the selected values
            const valueWithParents = newValues.concat(...matchesWithoutExistingValues);

            const valueIds = valueWithParents.map((item) => item.id);

            onChange(valueIds);
        } else {
            const adjustedValue = Array.isArray(value)
                ? value.map((item) => item.id)
                : (value?.id ?? value);

            onChange(adjustedValue);
        }
    };

    const areCategoriesLoading = areValueCategoriesLoading || isLoading;

    return (
        <Autocomplete
            loading={areCategoriesLoading}
            onChange={handleChange}
            value={currentValue}
            options={flattenedData}
            multiple={multiple}
            getOptionLabel={(option) => option.name}
            getOptionKey={(option) => option.id}
            fullWidth
            filterOptions={filterOptions}
            {...rest}
        />
    );
};

export default CategoriesAutocomplete;
