import memoize from 'micro-memoize';
import { useMemo } from 'react';
import { omit, pick } from 'remeda';

import usePrevious from '@@hooks/usePrevious';

export type UseCachedValidationOptions = {
    validate?: Validator[];
};
export type UseCachedValidationProps = {
    novalidate?: boolean;
    validate?: ((validate: UseCachedValidationOptions['validate']) => Validator[]) | Validator[];
    validateCacheKey?: string;
    required?: boolean;
    requiredCustom?: Validator;
};

const useCachedValidation = (
    validationFn,
    props: UseCachedValidationProps,
    options: UseCachedValidationOptions,
) => {
    const { validate, validateCacheKey = null } = props;
    const prevValidateCacheKey = usePrevious(validateCacheKey);
    const memoizedValidationFn = useMemo(
        () =>
            memoize(validationFn, {
                transformKey: (key) => [JSON.stringify(key)],
            }),
        [],
    );

    if (prevValidateCacheKey !== validateCacheKey) {
        if (memoizedValidationFn.cache) {
            memoizedValidationFn.cache.keys = [];
        }
    }

    const validateOverride = typeof validate === 'function';

    const transformProps = {
        ...props,
        validate: validateOverride ? validate(options.validate) : validate,
    };

    const nextValidate = memoizedValidationFn(
        pick(transformProps, ['novalidate', 'validate', 'required', 'requiredCustom']),
        {
            ...(validateOverride ? omit(options, ['validate']) : options),
        },
    );

    return nextValidate;
};

export default useCachedValidation;
