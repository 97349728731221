import { isEmpty } from 'lodash-es';
import { isTruthy, mapValues, pickBy } from 'remeda';
import validator from 'validator';
import { z } from 'zod';

import { type CropMarks, type FocusPoint, type ImageVariants } from '@@api/utils/schemas/schemas';

export type IImageTransformations = {
    cropMarks?: CropMarks | null;
    focusPoint?: FocusPoint | null;
    maxWidth?: number;
    maxHeight?: number;
    mimetype?: string | null;
    variants?: ImageVariants | null;
};

export const ZImageTransformationsOutput = z.object({
    // crop
    cropWidth: z.union([z.string(), z.number()]).optional(),
    cropHeight: z.union([z.string(), z.number()]).optional(),
    cropOffsetX: z.union([z.string(), z.number()]).optional(),
    cropOffsetY: z.union([z.string(), z.number()]).optional(),
    // focuspoint
    focusPointX: z.union([z.string(), z.number()]).optional(),
    focusPointY: z.union([z.string(), z.number()]).optional(),
    // limits
    maxWidth: z.union([z.string(), z.number()]).optional(),
    maxHeight: z.union([z.string(), z.number()]).optional(),
});

export type IImageTransformationsOutput = z.infer<typeof ZImageTransformationsOutput>;

const isFullSizeCrop = (cropMarks: CropMarks) => {
    const { width, height } = cropMarks;

    return Number(width) >= 1.0 && Number(height) >= 1.0;
};

export const SVG_MIME_TYPE = 'image/svg+xml';

const isSVG = (imageTransformations: IImageTransformations) =>
    imageTransformations && imageTransformations.mimetype === SVG_MIME_TYPE;

export const stringifyTransformations = (transformations: IImageTransformationsOutput): string => {
    const params = new URLSearchParams(
        mapValues(pickBy(transformations, isTruthy), (value) =>
            typeof value === 'number' ? value.toString() : value,
        ),
    );

    return params.toString();
};

export const getImageTransformationQueryParams = (
    imageUrl?: string | null,
    inputImageTransformations?: IImageTransformations,
    previewAspectRatio?: string,
) => {
    let transformations: IImageTransformationsOutput = {};

    const imageTransformations =
        previewAspectRatio && inputImageTransformations?.variants?.[previewAspectRatio]
            ? {
                  ...inputImageTransformations,
                  cropMarks: inputImageTransformations.variants[previewAspectRatio].cropMarks,
              }
            : inputImageTransformations;

    if (!imageUrl) {
        return transformations;
    }

    if (!imageTransformations || validator.isDataURI(imageUrl) || isSVG(imageTransformations)) {
        return transformations;
    }

    // handle allowed transformations
    const { cropMarks, focusPoint, maxWidth, maxHeight } = imageTransformations;

    if (cropMarks && !isFullSizeCrop(cropMarks)) {
        transformations = {
            ...transformations,
            // support pixel precision on any 1e5 pixel side-length sized images
            cropWidth: cropMarks.width.toFixed(4),
            cropHeight: cropMarks.height.toFixed(4),
            cropOffsetX: cropMarks.x.toFixed(4),
            cropOffsetY: cropMarks.y.toFixed(4),
        };
    }

    // Even though backend is not (yet) doing anything with the focus point value, we add it to the
    // images url, in order to trigger another `onLoad` event on the image after the
    // focus point has been changed (in order to recalculate its position).
    if (focusPoint) {
        transformations = {
            ...transformations,
            focusPointX: focusPoint.x.toFixed(4),
            focusPointY: focusPoint.y.toFixed(4),
        };
    }

    if (maxWidth && maxWidth > 0) {
        transformations = {
            ...transformations,
            // We want retina quality!
            maxWidth: 2 * maxWidth,
        };
    }

    if (maxHeight && maxHeight > 0) {
        transformations = {
            ...transformations,
            // We want retina quality!
            maxHeight: 2 * maxHeight,
        };
    }

    return transformations;
};

/**
 * Adds transformation parameters to an image url
 * @param imageUrl - Source url of the image
 * @param imageTransformations - A set of available transformations
 *
 * @return url with/without transformations, or null if the url or the transformations are invalid
 */
const buildImageTransformationUrl = (
    imageUrl?: string | null,
    inputImageTransformations?: IImageTransformations,
    previewAspectRatio?: string,
): string => {
    if (!imageUrl) {
        return '';
    }

    const queryParams = getImageTransformationQueryParams(
        imageUrl,
        inputImageTransformations,
        previewAspectRatio,
    );

    return isEmpty(queryParams) ? imageUrl : `${imageUrl}?${stringifyTransformations(queryParams)}`;
};

export default buildImageTransformationUrl;
