import { get, omit } from 'lodash-es';
import { isDeepEqual, merge, values } from 'remeda';

import { type Metadata } from '@@api/services/metadata/schemas';
import { type Tenant } from '@@api/services/tenant/schemas';
import { DEFAULT_TENANT_ID } from '@@containers/metadata/constants';
import { getTeasersFromMetadata } from '@@containers/metadata/utils';
import { getDefaultVariant, getVariantById } from '@@containers/TenantSpecific/arrayUtils';

import { DEFAULT_TEASER_ID, SEO_TEASER_ID, VARIANTS_KEY } from './constants';
import {
    type AnyTeaser,
    type DefaultTeaser,
    type Teaser,
    type TeaserId,
    type Teasers,
    type TeaserVariants,
} from './types';

export const getDefaultTeaserVariant = (teasers?: Metadata['teasers']) =>
    teasers && getDefaultVariant(teasers)[VARIANTS_KEY][DEFAULT_TEASER_ID];

const getTeaserVariantsFromTeasers = (
    teaser: Metadata['teasers'],
    tenantVariantId: Tenant['id'],
): Teasers => getVariantById(teaser, tenantVariantId);

const getTeaserVariantFromTeaser = <T>(teaser: Teasers, variantId: TeaserId): T =>
    get(teaser, `${VARIANTS_KEY}.${variantId}`);

const getTeaserFromMetadataTeaser = <T>(
    teaser: Metadata['teasers'],
    tenantVariantId: Tenant['id'],
    teaserVariantId: TeaserId,
): T =>
    getTeaserVariantFromTeaser<T>(
        getTeaserVariantsFromTeasers(teaser, tenantVariantId),
        teaserVariantId,
    );

export const getTenantSpecificTeaserVariant = <T extends Teaser>(
    entity: Metadata,
    tenantVariantId: Tenant['id'],
    teaserVariantId: TeaserId,
): Metadata & T => ({
    ...entity,
    ...getTeaserFromMetadataTeaser<T>(
        getTeasersFromMetadata(entity),
        tenantVariantId,
        teaserVariantId,
    ),
});

export const getDefaultTenantDefaultTeaser = (entity: Metadata): Metadata & DefaultTeaser =>
    getTenantSpecificTeaserVariant<DefaultTeaser>(entity, DEFAULT_TENANT_ID, DEFAULT_TEASER_ID);

export type DefaultSeoTeaserDiff = {
    tenantId: typeof DEFAULT_TENANT_ID;
    oldTitle: AnyTeaser['title'];
    newTitle: AnyTeaser['title'];
};

export const getDefaultSeoTeaserDiff = (
    prevSeoTeasers: Metadata['seoTeasers'],
    nextSeoTeasers: Metadata['seoTeasers'],
): DefaultSeoTeaserDiff | null => {
    const prevTitle = prevSeoTeasers[0].title;
    const nextTitle = nextSeoTeasers[0].title;

    if (prevTitle !== nextTitle) {
        return {
            tenantId: DEFAULT_TENANT_ID,
            oldTitle: prevTitle,
            newTitle: nextTitle,
        };
    }

    return null;
};

export const getSeoTeaserTitle = (titleHeader, title) =>
    [titleHeader, title].filter((str) => Boolean(str)).join(': ');

export const getAdoptDefaultValue = (variants) => {
    const seoTeaser = variants[SEO_TEASER_ID];
    const defaultTeaser = variants[DEFAULT_TEASER_ID];

    const seoTeaserTitleFromDefault = getSeoTeaserTitle(
        defaultTeaser.titleHeader,
        defaultTeaser.title,
    );

    // seoTeaser can be undefined in older articles, that were not migrated
    return seoTeaserTitleFromDefault === seoTeaser?.title && defaultTeaser.lead === seoTeaser?.lead;
};

export const isSocialMediaEqualToDefault = ({ variants }: Teasers) => {
    const socialMediaVariants: Partial<TeaserVariants> = omit(variants, [
        DEFAULT_TEASER_ID,
        SEO_TEASER_ID,
    ]);

    const socialMediaIsEqualToDefault = values(socialMediaVariants).every((variant) => {
        const defaultVariant = variants[DEFAULT_TEASER_ID];

        const reference = merge(omit(defaultVariant, ['useEmbedUrl', 'image']), {
            image: omit(defaultVariant.image, ['variants']),
        });
        const testedVariant = merge(omit(variant, ['useEmbedUrl', 'image']), {
            image: omit(variant.image, ['variants']),
        });

        return isDeepEqual(testedVariant, reference);
    });

    return socialMediaIsEqualToDefault;
};
