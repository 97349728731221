import { isEqual } from 'lodash-es';
import { type FocusEvent, useCallback, useEffect, useState } from 'react';

import usePrevious from '@@hooks/usePrevious';
import { debounce } from '@@utils/debounce';

type UseDebouncedValueProps<SerializedValue, DeserializedValue> = {
    deserialize?: (value: SerializedValue) => DeserializedValue;
    serialize?: (value: DeserializedValue) => SerializedValue;
    value: SerializedValue;
    delay?: number;
    onBlur?: (e: FocusEvent) => void;
    onChange?: (value: SerializedValue) => void;
    shouldCallOnChange?: (value?: SerializedValue) => boolean;
};

type UseDebouncedValueResult<DeserializedValue> = {
    value: DeserializedValue;
    onChange: (value: DeserializedValue) => void;
    onBlur: (e: FocusEvent) => void;
};

const DELAY = 600;

const useDebouncedValue = <SerializedValue, DeserializedValue>({
    deserialize = (v) => v as any,
    serialize = (v) => v as any,
    value,
    delay = DELAY,
    onChange = (v) => v,
    onBlur,
    shouldCallOnChange = () => true,
}: UseDebouncedValueProps<
    SerializedValue,
    DeserializedValue
>): UseDebouncedValueResult<DeserializedValue> => {
    const [valueState, setValueState] = useState(deserialize(value));
    const prevValue = usePrevious(value);

    useEffect(() => {
        if (!isEqual(value, prevValue)) {
            setValueState(deserialize(value));
        }
    }, [deserialize, prevValue, value]);

    const onChangeDebounced = useCallback(
        debounce(
            (value: DeserializedValue) => {
                const parsedValue = serialize(value);

                if (typeof parsedValue !== 'undefined' && shouldCallOnChange(parsedValue)) {
                    onChange(parsedValue);
                }
            },
            delay,
            { leading: true },
        ),
        [],
    );

    useEffect(
        () => () => {
            onChangeDebounced.flush();
        },
        [onChangeDebounced],
    );

    const handleChange = useCallback(
        (newValue: DeserializedValue) => {
            setValueState(newValue);

            onChangeDebounced(newValue);
        },
        [onChangeDebounced],
    );

    const handleBlur = useCallback(
        (e: FocusEvent) => {
            onChangeDebounced.flush();

            if (onBlur) {
                onBlur(e);
            }
        },
        [onBlur, onChangeDebounced],
    );

    return {
        value: valueState,
        onChange: handleChange,
        onBlur: handleBlur,
    };
};

export default useDebouncedValue;
