import { Container, GlobalStyles } from '@mui/material';
import { type ReactElement, useEffect, useState } from 'react';
import { useDragDropManager } from 'react-dnd';
import { matchPath, Outlet, useLocation, useNavigation } from 'react-router';

import { getAccessToken, getUserId, selectIsAuthenticated } from '@@auth/authSlice';
import useHandleUserChange from '@@auth/hooks/useHandleUserChange';
import parseJWT from '@@auth/utils/parseJWT';
import Loader from '@@components/Loader';
import MenuBar from '@@components/MenuBar';
import useLastRouteTracker from '@@containers/LastRouteTracker/useLastRouteTracker';
import Navigation from '@@containers/Navigation/Navigation';
import { InnerWrapper } from '@@editor/toolbars/floatingToolbar';
import { getContentLocaleSetting, getDevToolbarSmartSetting } from '@@settings/settingsSlice';
import { useSelector } from '@@store/hooks';
import { logPageView } from '@@utils/analyticsTracker';
import { addDataDogRUMAction, setDataDogRUMUserId } from '@@utils/dataDog';

import AppNotifications from './AppNotifications';
import PageLayout, {
    PageLayoutContent,
    PageLayoutFooter,
    PageLayoutHeader,
    PageLayoutNavigation,
    PageLayoutNotificationBar,
    PageLayoutSidebar,
    PageLayoutToolbar,
} from './PageLayout/PageLayout';
import PageTitle from './PageTitle';
import DeveloperToolbar from '../DeveloperToolbar/DeveloperToolbar';

const LOADER_THRESHOLD = 500;

type SlowLoaderProps = {
    content: ReactElement;
};

const SlowLoader = (props: SlowLoaderProps) => {
    const { content } = props;

    const [showLoader, setShowLoader] = useState(false);
    const navigation = useNavigation();

    useEffect(() => {
        if (navigation.state !== 'loading') {
            setShowLoader(false);
        } else {
            const timer = setTimeout(() => {
                setShowLoader(true);
            }, LOADER_THRESHOLD);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [navigation.state]);

    return navigation.state === 'loading' && showLoader ? <Loader /> : content;
};

// Important note: It is important to render global styles globally. Rendering them in a nested component, can lead to
// performance issues! This is what we've experienced for the floating toolbar of the richt text editor, which became
// a major incident!
// https://tamedia.atlassian.net/browse/CD2-8379
const StyledDndGlobalStyles = `
    body.dragging {
        // When a user drags an item to the bottom of the browser window, the default behaviour is to scroll downwards on the
        // page (which makes sense, in order to drop the dragged item somewhere further down on the page).
        // MenuBar prevented the user from doing so, because it kind of blocked the drag event from being propagated to
        // the scrollable area. In order to not block this, we disable 'pointer-events' while the user is dragging. This
        // fixes the problem.
        // Sidenote: 'useDragBlock.ts' adds the 'dragging' class to the body when a user starts to drag a rich text editor
        // element.
        ${MenuBar} {
            pointer-events: none;
        }

        // We want to make the floating toolbar disappear whenever the user is dragging something. Otherwise it would
        // distract the user from dragging and dropping stuff
        ${InnerWrapper} {
            display: none;
        }
    }
`;

const DndGlobalStyles = () => {
    const dndManager = useDragDropManager();

    useEffect(() => {
        const unsubscribe = dndManager.getMonitor().subscribeToStateChange(() => {
            if (dndManager.getMonitor().isDragging()) {
                document.body.classList.add('dragging');
            } else {
                document.body.classList.remove('dragging');
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return <GlobalStyles styles={StyledDndGlobalStyles} />;
};

export const pathsWithoutPageLayout = ['/cs', '/editor', '/isolated/*', '/embed/slideshow'];
export const pathsWithoutNavigation = ['/embed', '/preview'];

const matchesOneOfPaths = (paths, path) =>
    // this workaround is needed as path="/" which wraps every sibling in the navigation
    // also enables it for /embed as it matches "/".
    // an exact match for "/" breaks the navigation if a child route is rendered.
    paths.some((pathname) =>
        matchPath(
            {
                path: pathname,
                end: false,
                caseSensitive: false,
            },
            path,
        ),
    );

export const AppWrapper = () => {
    const location = useLocation();

    useLastRouteTracker();

    const { fetchPreferences, dispatchPreferences } = useHandleUserChange();
    // Do NOT get data directly from JWT, because if the same user has opened 2 tabs, and in window 1
    // he is changing for example the ui locale, then when refreshing tab 2, he must get the new lang. if we
    // would take JWT data, the new lang would only be applied after the next login
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const contentLocale = useSelector(getContentLocaleSetting);
    const devToolbar = useSelector(getDevToolbarSmartSetting);
    const userId = useSelector(getUserId);
    const accessToken = useSelector(getAccessToken);

    const content = isAuthenticated && !contentLocale ? <div /> : <Outlet />;

    // In order to avoid using acceptance environments when not necessary
    // We revert to default ones when token expires
    useEffect(() => {
        if (accessToken && !isAuthenticated) {
            localStorage.removeItem('CDII_API');
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated && userId) {
            fetchPreferences().then(({ body }) => {
                dispatchPreferences(body);
            });

            if (accessToken) {
                const jwt = parseJWT(accessToken);

                const username = jwt && jwt.user_name ? jwt.user_name : 'unknown';

                setDataDogRUMUserId(username);
            }
        }
    }, [isAuthenticated, userId, accessToken]);

    useEffect(() => {
        const reducedMotionEnabled = window.matchMedia?.(
            '(prefers-reduced-motion: reduce)',
        ).matches;

        if (reducedMotionEnabled) {
            addDataDogRUMAction('reducedMotionInfo', { reducedMotionEnabled });
        }
    }, []);

    useEffect(() => {
        logPageView(location);
    }, [location]);

    const isPathWithoutPageLayout = matchesOneOfPaths(pathsWithoutPageLayout, location.pathname);
    const isPathWithoutNavigation = matchesOneOfPaths(pathsWithoutNavigation, location.pathname);

    return (
        <Container disableGutters fixed sx={{ display: 'flex', minHeight: '100%' }}>
            <DndGlobalStyles />

            <PageTitle />

            {isAuthenticated && !isPathWithoutPageLayout ? (
                <>
                    <PageLayout>
                        <PageLayoutNavigation as="nav">
                            {!isPathWithoutNavigation && <Navigation />}
                        </PageLayoutNavigation>

                        <PageLayoutNotificationBar>
                            <AppNotifications />
                        </PageLayoutNotificationBar>

                        <PageLayoutHeader />

                        <PageLayoutSidebar as="aside" />

                        <PageLayoutContent as="main">
                            <SlowLoader content={content} />
                        </PageLayoutContent>

                        <PageLayoutToolbar />

                        <PageLayoutFooter />
                    </PageLayout>

                    {devToolbar && <DeveloperToolbar />}
                </>
            ) : (
                content
            )}
        </Container>
    );
};

export default AppWrapper;
