import { styled } from '@mui/material';
import { get, isEqual } from 'lodash-es';
import { Component, type ReactNode } from 'react';
import { identity } from 'remeda';

import { type FormFieldError } from '@@form/hooks/useReactHookFormFieldError';

export const ErrorContainer = styled('div')({
    overflow: 'auto',
});

export const ErrorStack = styled('pre')({
    overflow: 'auto',
});

type DisplayErrorProps = {
    errorMessage: string;
    errorStack: string;
    title?: string;
};

export const DisplayError = ({
    title = 'Something went wrong.',
    errorMessage,
    errorStack,
}: DisplayErrorProps) => (
    <ErrorContainer>
        <h1>{title}</h1>
        <details>
            <summary>{errorMessage}</summary>
            <ErrorStack>{errorStack}</ErrorStack>
        </details>
    </ErrorContainer>
);

const parseResetProp = (props, resetProp) =>
    get(props, 'resetOnChangedProp.parse', identity())(resetProp);

const getResetProp = (props) =>
    parseResetProp(props, props[get(props, 'resetOnChangedProp.fieldName')]);

type Props<T> = {
    children: ReactNode | ((props: { catchedError?: boolean }) => ReactNode);
    disableErrorBoundary?: boolean;
    fingerprint: string[];
    renderError?: (
        props: Props<T>,
        { errorMessage, errorStack }: Pick<State, 'errorMessage' | 'errorStack'>,
    ) => JSX.Element;
    resetOnChangedProp?: {
        fieldName: string;
        parse?: (value: T) => any;
    };
    onError: (props: Props<T>, error: FormFieldError) => void;
    value: T;
};

type State = {
    hasError: boolean;
    hadError: boolean;
    errorMessage: string;
    errorStack: string;
};

const initialState: State = { errorMessage: '', errorStack: '', hasError: false, hadError: false };

class ErrorBoundary<T> extends Component<Props<T>, State> {
    static getDerivedStateFromProps(props, state) {
        if (state.hasError && props.resetOnChangedProp) {
            const newProp = getResetProp(props);

            const oldProp = state.errorResetProp;

            if (newProp !== oldProp || !isEqual(newProp, oldProp)) {
                return {
                    ...state,
                    ...initialState,
                };
            }
        }

        return state;
    }

    static defaultProps = { disableErrorBoundary: false };

    state = initialState;

    componentDidCatch(error) {
        const { disableErrorBoundary, fingerprint, onError } = this.props;

        if (disableErrorBoundary) {
            throw error;
        }

        this.setState((prevState) => ({
            ...prevState,
            errorResetProp: getResetProp(this.props),
            errorMessage: `${error.name}: ${error.message}`,
            errorStack: error.stack,
            hasError: true,
            hadError: true,
        }));

        // meaningful exception name for error tracking (would be just "Error" otherwise)
        if (fingerprint) {
            // We create a new error instead of modifying the existing one to avoid
            // unhandled errors in the error boundary
            const newError = new Error(`${fingerprint} ${error.name}`, error);

            if (onError) {
                // @ts-expect-error - we would need to fix all types of form field errors to make this work
                onError(this.props, newError);
            }
        } else {
            if (onError) {
                onError(this.props, error);
            }
        }
    }

    render() {
        const { children, renderError } = this.props;
        const { hasError, errorMessage, errorStack } = this.state;

        if (hasError) {
            return renderError ? (
                renderError(this.props, { errorMessage, errorStack })
            ) : (
                <DisplayError errorMessage={errorMessage} errorStack={errorStack} />
            );
        }

        return typeof children === 'function'
            ? children(this.state.hadError ? { catchedError: true } : {})
            : children;
    }
}

export default ErrorBoundary;
