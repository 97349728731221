import { get, pickBy, set } from 'lodash-es';
import { mapValues } from 'remeda';

// removes all null, undefined and empty object entries
export const cleanObject = (obj, cleanArraysAndObjects = true) =>
    pickBy(obj, (v) => {
        if (v !== null && typeof v !== 'undefined') {
            if (v === '') {
                return;
            }
            if (cleanArraysAndObjects && typeof v === 'object' && Object.keys(v).length <= 0) {
                return;
            }

            return true;
        }
    });

export const resolveOptions = <
    Options extends Record<string, any>,
    Props extends Record<string, any>,
>(
    options: {
        [K in keyof Options]: Options[K] | ((props: Props, options: Options) => Options[K]);
    },
    props: Props,
): Options =>
    mapValues(options, (option) =>
        typeof option === 'function' ? option(props, options) : option,
    ) as Options;

export const pickDeep = (object, paths) => {
    const newObject = {};

    paths.forEach((path) => {
        set(newObject, path, get(object, path));
    });

    return newObject;
};

export const notNull = (arg?) => arg !== null;
