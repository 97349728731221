import {
    Divider,
    ListItemIcon,
    MenuItem,
    MenuList,
    Popover,
    type PopoverProps,
    styled,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    type CSSProperties,
    type KeyboardEvent,
    type MouseEvent,
    type MouseEventHandler,
    type ReactNode,
    type Ref,
    useRef,
    useState,
} from 'react';
import { findIndex } from 'remeda';
import { useSlateStatic } from 'slate-react';

import Icon from '@@components/Icon';
import { ReactEditor } from '@@editor/helpers';
import { toPx } from '@@utils/number';

const DEFAULT_COLUMN_WIDTH = 200;

export type Element = {
    label: string;
    hidden?: boolean;
    iconName?: string;
    iconColor?: string;
    selected?: boolean;
    title?: string;
    onMouseDown?: (e: MouseEvent | KeyboardEvent) => void;
    style?: CSSProperties;
    disabled?: boolean;
    tooltipMessage?: string;
};
export type TabElement = {
    name: string;
    elements: Element[];
};

type TabbedProps =
    | {
          withTabs: true;
          tabElements: TabElement[];
      }
    | { withTabs?: false; elements: Element[] };

type Props = TabbedProps & {
    autoFocus?: boolean;
    className?: string;
    justifyButtons?: string;
    columns?: number;
    columnWidth?: number;
    onClose?: VoidFunction;
    renderToggleButton: (props: {
        onClick: MouseEventHandler<HTMLElement>;
        ref: Ref<HTMLButtonElement>;
        onMouseDown?: MouseEventHandler<HTMLElement>;
    }) => ReactNode;
} & Pick<PopoverProps, 'anchorOrigin' | 'transformOrigin' | 'container'>;

const Panel = styled(MenuList, { shouldForwardProp: (prop: string) => !prop.startsWith('$') })<{
    $columns: number;
    $columnWidth: number;
    $rows: number;
}>(({ theme, $columns, $columnWidth, $rows }) => ({
    width: '100%',
    background: theme.palette.primary['100'],
    display: 'grid',
    gap: 1,
    grid: `repeat(${$rows}, 1fr) / repeat(${$columns}, ${toPx($columnWidth)})`,
    gridAutoFlow: 'column',
}));

const elementStyles = (props) => ({
    ...(props.$justifyContent && { justifyContent: props.$justifyContent }),
    backgroundColor: props.theme.palette.background.paper,
});

const Element = styled(MenuItem, { shouldForwardProp: (prop: string) => !prop.startsWith('$') })<{
    $justifyContent?: CSSProperties['justifyContent'];
}>(elementStyles);

const EmptyElement = styled('li')(elementStyles);

const ElementsDropdown = (props: Props) => {
    const {
        className,
        anchorOrigin = { horizontal: 'center', vertical: 'bottom' },
        transformOrigin = { horizontal: 'center', vertical: 'top' },
        autoFocus = false,
        justifyButtons = 'flex-start',
        columns = 3,
        columnWidth = DEFAULT_COLUMN_WIDTH,
        container,
        renderToggleButton,
        onClose,
    } = props;
    // In case a tab has no element, it will not be displayed, but its index will be used to
    // determine the initial selected tab. This is why we need to find the first tab that
    // contains elements, to set it as the initial selected tab.
    const firstTabWithElements = props.withTabs
        ? findIndex(props.tabElements, (tab) => tab.elements.length > 0)
        : 0;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState(firstTabWithElements);
    const editor = useSlateStatic();
    const panelRef = useRef<HTMLUListElement>(null);

    const handleClickOnToggleButton: MouseEventHandler<HTMLDivElement> = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);

        requestAnimationFrame(() => {
            ReactEditor.focus(editor);
        });
    };

    const renderPanelContent = () => {
        const tabs = props.withTabs ? props.tabElements : [];
        const items = props.withTabs ? props.tabElements[selectedTab].elements : props.elements;
        const filteredItems = items.filter((item) => !item.hidden);
        const remainder = filteredItems.length % columns;
        const rows = Math.ceil(filteredItems.length / columns);

        return (
            <>
                {props.withTabs && (
                    <>
                        <Tabs value={selectedTab}>
                            {tabs.map(
                                (tab, index) =>
                                    tab.elements.length > 0 && (
                                        <Tab
                                            key={index}
                                            label={tab.name}
                                            value={index}
                                            onMouseDown={(e) => {
                                                // In order to keep the focus on the editor, which is
                                                // required to have a proper UX, we need to
                                                // `preventDefault` here
                                                e.preventDefault();
                                            }}
                                            onClick={() => setSelectedTab(index)}
                                        />
                                    ),
                            )}
                        </Tabs>

                        <Divider />
                    </>
                )}

                <Panel
                    ref={panelRef}
                    $columns={columns}
                    $columnWidth={columnWidth}
                    $rows={rows}
                    role="tabpanel"
                    dense
                    disablePadding
                    autoFocusItem={autoFocus}
                >
                    {filteredItems.map((item, index) => {
                        const tooltipMessage = item.tooltipMessage;

                        const element = (
                            <Element
                                disabled={item.disabled}
                                $justifyContent={justifyButtons}
                                key={index}
                                selected={item.selected}
                                title={item.title}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        // In order to keep the focus on the editor, which is
                                        // required to have a proper UX, we need to
                                        // `preventDefault` here
                                        e.preventDefault();

                                        item.onMouseDown?.(e);

                                        handleClose();
                                    }
                                }}
                                onMouseDown={(e) => {
                                    // In order to keep the focus on the editor, which is
                                    // required to have a proper UX, we need to
                                    // `preventDefault` here
                                    e.preventDefault();

                                    item.onMouseDown?.(e);

                                    handleClose();
                                }}
                            >
                                <Typography
                                    color="primary.main"
                                    variant="medium"
                                    display="inline-flex"
                                    alignItems="center"
                                    noWrap
                                    sx={{ ...(item.style || {}) }}
                                >
                                    {item.iconName && (
                                        <ListItemIcon sx={{ color: 'inherit' }}>
                                            <Icon name={item.iconName} color={item.iconColor} />
                                        </ListItemIcon>
                                    )}

                                    {item.label}
                                </Typography>
                            </Element>
                        );

                        if (tooltipMessage) {
                            return (
                                <Tooltip key={index} title={tooltipMessage} placement="top">
                                    <span>{element}</span>
                                </Tooltip>
                            );
                        }

                        return element;
                    })}

                    {remainder > 0 && (
                        <EmptyElement
                            style={{
                                gridColumnStart: remainder + 1,
                                gridColumnEnd: -1,
                                gridRowStart: -remainder,
                                gridRowEnd: rows,
                            }}
                        />
                    )}
                </Panel>
            </>
        );
    };

    return (
        <>
            {renderToggleButton({
                onMouseDown: (e) => {
                    e.preventDefault();
                },
                onClick: handleClickOnToggleButton,
                ref: setAnchorEl,
            })}

            <Popover
                {...{ className, anchorOrigin, transformOrigin, anchorEl, open }}
                // By default popover renders components using a portal which injects elements into `document.body`. This generates
                // problems, if we want to display popover components within modal dialogs, since we use the native dialog element,
                // which renders modal dialogs in the top layer, which will always be displayed ABOVE everything else which is not
                // rendered in the top layer (like stuff we inject into `document.body`).
                // This can be removed, once we've migrated our custom modal implementation to MUI modals.
                container={container ?? anchorEl?.parentElement}
                onClose={handleClose}
                TransitionProps={{
                    onExited: onClose,
                }}
            >
                {renderPanelContent()}
            </Popover>
        </>
    );
};

export default ElementsDropdown;
