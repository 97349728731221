import { type UserRole } from '@@auth/constants';

type JWT = {
    iss: string;
    sub: string;
    aud: string;
    exp: number;
    unity_user_first_name?: string;
    nbf?: number;
    unity_user_last_name?: string;
    user_name?: string;
    scope?: string[];
    unity_user_id?: string;
    iat?: number;
    authorities?: UserRole[];
};

export default (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    try {
        return JSON.parse(window.atob(base64)) as JWT;
    } catch (e) {
        return false;
    }
};
