import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import { pathOr, stringToPath } from 'remeda';

import { type Collapsing, type Filters } from '@@api/services/auth/schemas';
import { ROLES } from '@@auth/constants';
import config from '@@config';
import ContentStatus from '@@constants/ContentStatus';
import { type MODE_BIG, type MODE_ICON, MODE_SMALL } from '@@containers/DeveloperToolbar/constants';
import { type SearchNamespace } from '@@containers/Search/types';
import { type ContentLanguage, LANG_CODES } from '@@lib/i18n/constants';

export type SettingsState = {
    filters: Filters;
    placementColumns: (string | null)[];
    contentLocale?: ContentLanguage;
    betaFeaturesEnabled: boolean;
    reducedUI: boolean;
    devToolbar: typeof MODE_BIG | typeof MODE_SMALL | typeof MODE_ICON;
    noFormRestoration: boolean;
    collapsing?: Collapsing;
    userRole?: ValueOf<typeof ROLES>;
};

const initialStateFilters = {
    default: {
        tenantIds: null,
    },
    curatedList: {
        q: '',
        tenantIds: null,
        userIds: null,
        categoryIds: null,
        tagIds: null,
        publicationStatus: null,
        contentStatus: [ContentStatus.PROOFREAD],
        publishedBefore: '',
        publishedAfter: '',
    },
};

const initialState: SettingsState = {
    filters: {
        [LANG_CODES.GERMAN]: initialStateFilters,
        [LANG_CODES.FRENCH]: initialStateFilters,
    },
    placementColumns: [],
    // Do not set a default content language! We need to wait until we've fetched the value from BE before
    // rendering the app. This is done within `AppWrapper`.
    contentLocale: undefined,
    betaFeaturesEnabled: false,
    reducedUI: false,
    devToolbar: MODE_SMALL,
    noFormRestoration: false,
    collapsing: {
        publicationCards: {
            placement: {
                collapsed: false,
                sections: {
                    categoriesPositions: {
                        showMore: true,
                    },
                },
            },
            teasers: {
                collapsed: false,
                sections: {
                    socialTeasers: {
                        showMore: true,
                    },
                },
            },
            scheduledPlacing: {
                collapsed: false,
                sections: {
                    scheduledPublication: {
                        showMore: true,
                    },
                },
            },
            additionalSettings: {
                collapsed: false,
                sections: {
                    typeSettings: {
                        showMore: true,
                    },
                    displaySettings: {
                        showMore: true,
                    },
                },
            },
            seoTeasers: {
                collapsed: false,
            },
            urlSlugs: {
                collapsed: false,
            },
            styles: {
                collapsed: false,
            },
            tags: {
                collapsed: false,
            },
            infobox: {
                collapsed: false,
            },
            kickword: {
                collapsed: false,
            },
            note: {
                collapsed: false,
            },
            printMetadata: {
                collapsed: false,
            },
        },
    },
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setSetting(state, action: PayloadAction<{ path: string; value: any }>) {
            const { path, value } = action.payload;
            const keyTokens = path.split('.');
            const updateInfo: Record<string, any> = {};
            let updateInfoPointer = updateInfo;

            keyTokens.forEach((keyToken, index) => {
                const isLast = index === keyTokens.length - 1;

                updateInfoPointer = updateInfoPointer[keyToken] = isLast ? { $set: value } : {};
            });

            if (path === 'contentLocale' && value !== state[path]) {
                // Placement columns are also contentLocale specific and should be reset
                updateInfo.placementColumns = { $set: initialState.placementColumns };
            }

            return update(state, updateInfo);
        },
        placementColumnLayoutChange(
            state,
            action: PayloadAction<SettingsState['placementColumns']>,
        ) {
            return {
                ...state,
                placementColumns: action.payload,
            };
        },
    },
    selectors: {
        getContentLocaleSetting: (state) => state.contentLocale || LANG_CODES.GERMAN,
        getCollapsingSetting: (state) => state.collapsing,
        getPlacementColumnsSetting: (state) => state.placementColumns,
        getFiltersSetting: (state, namespace: SearchNamespace) => {
            const contentLocale = settingsSlice.getSelectors().getContentLocaleSetting(state);

            return pathOr(
                state,
                stringToPath(`filters.${contentLocale}.${namespace}`),
                initialStateFilters[namespace],
            );
        },
        getReducedUISmartSetting: (state) => {
            const value = state.reducedUI;

            if ((config.env === 'production' || config.env === 'prod') && !config.isVisualTest) {
                return true;
            }

            // If there is set a user setting, use that value
            if (value != null) {
                return value;
            }

            // If we are not on production and no setting is set, use the following default
            // value: UI will not be reduced for visual tests and development systems. For
            // other systems it is (for example: staging, dynamic)
            return !config.isVisualTest && config.env !== 'development';
        },
        getBetaFeaturesEnabledSmartSetting: (state) => {
            const value = state.betaFeaturesEnabled;

            if (value !== undefined) {
                return value;
            }

            return false;
        },
        getUserRoleSetting: (state) => state.userRole,
        getUserRoleSmartSetting: (state) => {
            // If we are on production while not running visual tests, skip special treatment.
            // If we are not on production, or running visual tests in production mode, special
            // treatment is possible

            const value = settingsSlice.getSelectors().getUserRoleSetting(state);

            // TODO there is something wrong with these ifs. Conditions overlaps etc.
            if (config.env !== 'production' || config.isVisualTest) {
                // If there is set a user setting, use that value
                if (value != null) {
                    return value;
                }

                // If we are running visual tests or we are on development, we always will be
                // admins
                if (config.isVisualTest || config.env === 'development') {
                    return ROLES.ADMIN;
                }
            }

            // If there was no special rule applied, we return null.
            // Use a fallback to the authSlice in your selector
            // State between slices shouldn't be accessed and/or shared
            return null;
        },
        getDevToolbarSmartSetting: (state) => {
            const value = state.devToolbar;

            if (config.devToolbarDisabled || config.isVisualTest) {
                return false;
            }

            // If there is a setting for devToolbar, return it
            if (value != null) {
                return value;
            }

            // On all systems, but visual tests, the devToolbar is shown
            // by default
            if (!config.isVisualTest) {
                return MODE_SMALL;
            }

            return false;
        },
        getNoFormRestorationSmartSetting: (state) => {
            const value = state.noFormRestoration;

            if (config.isVisualTest) {
                if (value != null) {
                    return value;
                }

                return true;
            }

            return value;
        },
    },
});

export const { setSetting, placementColumnLayoutChange } = settingsSlice.actions;
export const {
    getContentLocaleSetting,
    getCollapsingSetting,
    getFiltersSetting,
    getBetaFeaturesEnabledSmartSetting,
    getDevToolbarSmartSetting,
    getNoFormRestorationSmartSetting,
    getReducedUISmartSetting,
    getUserRoleSetting,
    getUserRoleSmartSetting,
    getPlacementColumnsSetting,
} = settingsSlice.selectors;
export default settingsSlice.reducer;
