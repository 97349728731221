import { ListItemIcon, MenuItem, type MenuItemProps, Tooltip, Typography } from '@mui/material';
import { type ElementType } from 'react';

import Icon from '@@components/Icon';

type Props<RootComponent extends ElementType> = MenuItemProps<
    RootComponent,
    { component?: RootComponent }
> & {
    iconName?: string;
    tooltip?: string | boolean;
};

const ContextMenuItem = <RootComponent extends ElementType>({
    children,
    color,
    iconName,
    tooltip,
    ...props
}: Props<RootComponent>) => (
    <Tooltip title={tooltip} placement="left">
        <div>
            <MenuItem {...props}>
                <Typography
                    color={color || 'primary.main'}
                    variant="button"
                    display="inline-flex"
                    alignItems="center"
                >
                    {iconName && (
                        <ListItemIcon sx={{ color: 'inherit' }}>
                            <Icon name={iconName} />
                        </ListItemIcon>
                    )}

                    {children}
                </Typography>
            </MenuItem>
        </div>
    </Tooltip>
);

export default ContextMenuItem;
