import { zodResolver } from '@hookform/resolvers/zod';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import {
    type TriggerConfig,
    // This is the only place in this stack where we use `useForm` directly! Use `useReactHookForm`
    // in all other places.
    // eslint-disable-next-line no-restricted-imports
    useForm,
    type UseFormProps,
    type UseFormReturn,
} from 'react-hook-form';
import { doNothing, omit } from 'remeda';
import { type z } from 'zod';

import { type ModalLeavePromptProps } from '@@form/hooks/useModalLeavePrompt';
import usePrevious from '@@hooks/usePrevious';

import { REVALIDATION_MODE, VALIDATION_MODE } from './constants';
import { formValidationErrorMap } from './errorMap';
import { createActions, type ReactHookFormMetaContext, reducer } from './ReactHookFormMetaContext';
import {
    type ReactHookFormBaseProps,
    type UseReactHookFormReturn as LegacyUseReactHookFormReturn,
} from './types';
import useReactHookFormPersist from './useReactHookFormPersist';
import { mergeFormFieldValues } from './utils';

type TriggerOptions = TriggerConfig & {
    force?: boolean;
};

/**
 * @deprecated Remove when all forms are migrated to Zod
 */
const useLegacyReactHookForm = (props: ReactHookFormBaseProps): LegacyUseReactHookFormReturn => {
    const {
        // When set to true, the form will retain the value of
        // dirty fields and update every registered field which is still pristine when
        // reinitializing. When this option is not set (the default), reinitializing the form
        // replaces all field values. This option is useful in situations where the form has
        // live updates or continues to be editable after form submission; it prevents
        // reinitialization from overwriting user changes.
        keepDirtyOnReinitialize = true,
        values: initialValues,
        onSubmit,
        onDelete = doNothing(),
        onCancel = doNothing(),
        formName,
        persist = false,
        mode = VALIDATION_MODE.onSubmit,
        reValidateMode = REVALIDATION_MODE.onChange,
        atoms,
        isSubmitting: isMutationSubmitting = false,
    } = props;

    const formMetaInitialState = { formName, registeredFields: [], registeredFieldArrays: [] };
    const [formMetaState, formMetaDispatch] = useReducer(reducer, formMetaInitialState);
    const { registeredFields, registeredFieldArrays } = formMetaState;
    const formMetaActions = createActions(formMetaDispatch);

    const prevInitialValues = usePrevious(initialValues);

    const form = useForm({
        values: initialValues,
        criteriaMode: 'all',
        shouldFocusError: false,
        shouldUnregister: false,
        mode,
        reValidateMode,
        ...(keepDirtyOnReinitialize
            ? {
                  resetOptions: {
                      keepDirty: true,
                      keepDirtyValues: true,
                      keepTouched: true,
                  },
              }
            : {}),
    });

    const {
        formState: { dirtyFields, isSubmitting, isDirty },
        reset,
        getValues,
    } = form;

    useEffect(() => {
        if (
            prevInitialValues !== null &&
            !isEqual(prevInitialValues, initialValues) &&
            !isSubmitting
        ) {
            if (keepDirtyOnReinitialize) {
                const newValues = mergeFormFieldValues(
                    getValues(),
                    initialValues,
                    dirtyFields,
                    registeredFields,
                    registeredFieldArrays,
                    atoms,
                );

                // Then re-apply values of dirty fields in a second step (since dirty values
                // got reset during the first reset)
                reset(newValues, {
                    keepErrors: true,
                    keepDefaultValues: true,
                    keepValues: false,
                    keepDirty: false,
                    keepDirtyValues: false,
                    keepIsSubmitted: true,
                    keepTouched: true,
                    keepIsValid: true,
                    keepSubmitCount: true,
                });
            } else {
                reset(initialValues);
            }
        }
    }, [
        prevInitialValues,
        initialValues,
        isSubmitting,
        reset,
        keepDirtyOnReinitialize,
        dirtyFields,
        getValues,
        registeredFields,
        registeredFieldArrays,
        atoms,
    ]);

    const { clearPersistedForm } = useReactHookFormPersist(formName, form, { persist });

    const handleSubmit: LegacyUseReactHookFormReturn['componentProps']['onSubmit'] = useCallback(
        (values) => {
            onSubmit(values, form, {
                submit: form.handleSubmit(handleSubmit),
                clearPersistedForm,
            });
        },
        [clearPersistedForm, form, onSubmit],
    );

    const handleDelete: LegacyUseReactHookFormReturn['componentProps']['onDelete'] =
        useCallback(() => {
            onDelete(form, { clearPersistedForm });
        }, [onDelete, clearPersistedForm, form]);

    const handleCancel = useCallback(() => {
        reset();

        onCancel();
        clearPersistedForm();
    }, [onCancel, reset, clearPersistedForm]);

    // In order to support our form field validation strategy (only validate fields on change and
    // after the form has been tried to be submitted at least once), we need to make `setValue` a bit smarter
    // https://github.com/react-hook-form/react-hook-form/projects/1#card-90869999
    const setValue = useCallback(
        (name, value, options) => {
            let shouldValidate;

            if (
                (!mode || mode === 'onSubmit') &&
                (!reValidateMode || reValidateMode === 'onChange')
            ) {
                shouldValidate = form.formState.isSubmitted && !form.formState.isSubmitSuccessful;
            }

            form.setValue(name, value, {
                shouldDirty: true,
                shouldValidate,
                ...options,
            });
        },
        [form, mode, reValidateMode],
    );

    // In order to support our form field validation strategy (only validate fields on change and
    // after the form has been tried to be submitted at least once), we need to make `trigger` a bit smarter
    // https://github.com/react-hook-form/react-hook-form/projects/1#card-90869999
    const trigger = useCallback(
        (name, options: TriggerOptions = {}) => {
            const { force } = options;

            if (
                !force &&
                (!mode || mode === 'onSubmit') &&
                !(form.formState.isSubmitted && !form.formState.isSubmitSuccessful)
            ) {
                return Promise.resolve(false);
            }

            return form.trigger(name, omit(options, ['force']));
        },
        [form, mode],
    );

    const enhancedForm = {
        ...form,
        setValue,
        submit: form.handleSubmit(handleSubmit),
        trigger,
    };

    const formMeta = useMemo(
        () => ({ formMetaState, ...formMetaActions }),
        [formMetaState, formMetaActions],
    );

    return {
        form: enhancedForm,
        formMeta,
        componentProps: {
            ...props,
            dirty: isDirty,
            pristine: !isDirty,
            submitting: isSubmitting || isMutationSubmitting,
            handleSubmit: form.handleSubmit,
            onSubmit: handleSubmit,
            onDelete: handleDelete,
            onCancel: handleCancel,
        },
    };
};

type ReactHookFormDebug = 'off' | 'devtool' | 'resolver' | 'all';

export type UseReactHookFormProps<
    Schema extends z.ZodTypeAny = z.ZodTypeAny,
    Context = unknown,
> = Pick<
    UseFormProps<z.input<Schema>, Context>,
    'disabled' | 'mode' | 'reValidateMode' | 'errors'
> & {
    schema: Schema;
    atoms?: RegExp[];
    className?: string;
    formName: string;
    isLoading?: boolean;
    keepDirtyOnReinitialize?: boolean;
    persist?: boolean;
    modalLeavePrompt?: ModalLeavePromptProps<
        z.input<Schema>,
        Context,
        z.infer<Schema>
    >['modalLeavePrompt'];
    values: NonNullable<UseFormProps<z.input<Schema>, Context>['values']>;
    formNameDependencies?: UnknownObject;
    debug?: ReactHookFormDebug;
};

export type UseReactHookFormReturn<
    Schema extends z.ZodTypeAny = z.ZodTypeAny,
    Context = unknown,
> = {
    methods: UseFormReturn<z.input<Schema>, Context, z.infer<Schema>>;
    formMeta: ReactHookFormMetaContext;
    meta: {
        clearPersistedForm: VoidFunction;
        debug?: ReactHookFormDebug;
    };
    name: string;
};

export const useReactHookForm = <Schema extends z.ZodTypeAny = z.ZodTypeAny, Context = unknown>(
    props: UseReactHookFormProps<Schema, Context>,
): UseReactHookFormReturn<Schema, Context> => {
    const {
        // When set to true, the form will retain the value of
        // dirty fields and update every registered field which is still pristine when
        // reinitializing. When this option is not set (the default), reinitializing the form
        // replaces all field values. This option is useful in situations where the form has
        // live updates or continues to be editable after form submission; it prevents
        // reinitialization from overwriting user changes.
        keepDirtyOnReinitialize = true,
        values: initialValues,
        formName,
        persist = false,
        mode = VALIDATION_MODE.onSubmit,
        reValidateMode = REVALIDATION_MODE.onChange,
        atoms,
        schema,
        debug = 'off',
    } = props;

    const formMetaInitialState = { formName, registeredFields: [], registeredFieldArrays: [] };
    const [formMetaState, formMetaDispatch] = useReducer(reducer, formMetaInitialState);
    const { registeredFields, registeredFieldArrays } = formMetaState;
    const formMetaActions = createActions(formMetaDispatch);

    const prevInitialValues = usePrevious(initialValues);

    const form = useForm<z.input<Schema>, Context, z.infer<Schema>>({
        values: initialValues,
        criteriaMode: 'all',
        shouldFocusError: false,
        shouldUnregister: false,
        mode,
        reValidateMode,
        resolver: async (data, context, options) => {
            const result = await zodResolver(schema, { errorMap: formValidationErrorMap })(
                data,
                context,
                options,
            );

            if (debug === 'resolver' || debug === 'all') {
                console.log('formData', data);
                console.log('validation result', result);
            }

            return result;
        },
        ...(keepDirtyOnReinitialize
            ? {
                  resetOptions: {
                      keepDirty: true,
                      keepDirtyValues: true,
                      keepTouched: true,
                  },
              }
            : {}),
    });

    const {
        formState: { dirtyFields, isSubmitting },
        reset,
        getValues,
    } = form;

    useEffect(() => {
        if (
            prevInitialValues !== null &&
            !isEqual(prevInitialValues, initialValues) &&
            !isSubmitting
        ) {
            if (keepDirtyOnReinitialize) {
                const newValues = mergeFormFieldValues(
                    getValues(),
                    initialValues,
                    dirtyFields,
                    registeredFields,
                    registeredFieldArrays,
                    atoms,
                );

                // Then re-apply values of dirty fields in a second step (since dirty values
                // got reset during the first reset)
                reset(newValues, {
                    keepErrors: true,
                    keepDefaultValues: true,
                    keepValues: false,
                    keepDirty: false,
                    keepDirtyValues: false,
                    keepIsSubmitted: true,
                    keepTouched: true,
                    keepIsValid: true,
                    keepSubmitCount: true,
                });
            } else {
                reset(initialValues);
            }
        }
    }, [
        prevInitialValues,
        initialValues,
        isSubmitting,
        reset,
        keepDirtyOnReinitialize,
        dirtyFields,
        getValues,
        registeredFields,
        registeredFieldArrays,
        atoms,
    ]);

    const { clearPersistedForm } = useReactHookFormPersist(formName, form, { persist });

    // In order to support our form field validation strategy (only validate fields on change and
    // after the form has been tried to be submitted at least once), we need to make `setValue` a bit smarter
    // https://github.com/react-hook-form/react-hook-form/projects/1#card-90869999
    const setValue: UseFormReturn<z.input<Schema>, Context, z.infer<Schema>>['setValue'] =
        useCallback(
            (name, value, options) => {
                let shouldValidate;

                if (
                    (!mode || mode === 'onSubmit') &&
                    (!reValidateMode || reValidateMode === 'onChange')
                ) {
                    shouldValidate =
                        form.formState.isSubmitted && !form.formState.isSubmitSuccessful;
                }

                form.setValue(name, value, {
                    shouldDirty: true,
                    shouldValidate,
                    ...options,
                });
            },
            [form, mode, reValidateMode],
        );

    type CustomTrigger = (
        name: Parameters<UseFormReturn<z.input<Schema>, Context, z.infer<Schema>>['trigger']>[0],
        options?: TriggerConfig & {
            force?: boolean;
        },
    ) => ReturnType<UseFormReturn<z.input<Schema>, Context, z.infer<Schema>>['trigger']>;
    // In order to support our form field validation strategy (only validate fields on change and
    // after the form has been tried to be submitted at least once), we need to make `trigger` a bit smarter
    // https://github.com/react-hook-form/react-hook-form/projects/1#card-90869999
    const trigger: CustomTrigger = useCallback(
        (name, options: TriggerOptions = {}) => {
            const { force } = options;

            if (
                !force &&
                (!mode || mode === 'onSubmit') &&
                !(form.formState.isSubmitted && !form.formState.isSubmitSuccessful)
            ) {
                return Promise.resolve(false);
            }

            return form.trigger(name, omit(options, ['force']));
        },
        [form, mode],
    );

    const formMeta = useMemo(
        () => ({ formMetaState, ...formMetaActions }),
        [formMetaState, formMetaActions],
    );

    return {
        methods: {
            ...form,
            setValue,
            trigger,
        },
        formMeta,
        meta: { clearPersistedForm, debug },
        name: formName,
    };
};

export default useLegacyReactHookForm;
