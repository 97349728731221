import { z } from 'zod';

import { LANG_CODES } from '@@lib/i18n/constants';
import { AUDIENCE_TYPE } from '@@utils/enums/tenants';

import { Entity } from '../../utils/schemas/schemas';

export enum SitemapTypesEnum {
    CATEGORY = 'category',
    INTERNAL = 'internal',
    EXTERNAL = 'external',
    GROUP = 'group',
    CLIENT_PAGE = 'clientPage',
}

const SitemapBase = Entity.extend({
    id: z.number().optional(),
    parentId: z.number().nullish(),
    tenantId: z.number(),
    name: z.string(),
    availableFor: z.object({
        app: z.boolean(),
        desktop: z.boolean(),
    }),
});

type SitemapWithChildren = {
    children?: Sitemap[];
};

const SitemapWithChildren: z.ZodType<SitemapWithChildren> = z.object({
    children: z.lazy(() => z.array(Sitemap)).optional(),
});

const SitemapCategory = z.intersection(
    SitemapBase.extend({
        type: z.literal(SitemapTypesEnum.CATEGORY),
        subtitle: z.string().nullish(),
        categoryId: z.number(),
        categoryType: z.string().optional(),
        categoryUrlSlug: z.string(),
        pageStyle: z.string().nullable(),
        htmlMetaTitle: z.string().optional(),
        htmlMetaDescription: z.string().optional(),
        allowRobots: z.boolean(),
    }),
    SitemapWithChildren,
);

export type SitemapCategory = z.infer<typeof SitemapCategory>;

const SitemapInternalLink = z.intersection(
    SitemapBase.extend({
        type: z.literal(SitemapTypesEnum.INTERNAL),
        metadataId: z.number(),
        contentUrlSlug: z.string().nullable(),
        pageStyle: z.string().nullable(),
    }),
    SitemapWithChildren,
);

export type SitemapInternalLink = z.infer<typeof SitemapInternalLink>;

const SitemapExternalLink = z.intersection(
    SitemapBase.extend({
        type: z.literal(SitemapTypesEnum.EXTERNAL),
        contentLink: z.string(),
        newWindow: z.boolean().optional(),
        pageStyle: z.string().nullable(),
    }),
    SitemapWithChildren,
);

export type SitemapExternalLink = z.infer<typeof SitemapExternalLink>;

const SitemapGroup = z.intersection(
    SitemapBase.extend({
        type: z.literal(SitemapTypesEnum.GROUP),
    }),
    SitemapWithChildren,
);

export type SitemapGroup = z.infer<typeof SitemapGroup>;

const SitemapClientPageBase = SitemapBase.extend({
    type: z.literal(SitemapTypesEnum.CLIENT_PAGE),
    clientPageIdentifier: z.string().nullable(),
    clientPagePath: z.string(),
    htmlMetaTitle: z.string(),
    htmlMetaDescription: z.string(),
    allowRobots: z.boolean(),
});

const SitemapClientPage = z.intersection(SitemapClientPageBase, SitemapWithChildren);

export const Sitemap = z.union([
    SitemapCategory,
    SitemapInternalLink,
    SitemapExternalLink,
    SitemapClientPage,
    SitemapGroup,
]);

export const UnitySitemap = Sitemap;

export type Sitemap = z.infer<typeof Sitemap>;
export type UnitySitemap = z.infer<typeof UnitySitemap>;

export const isUnityCategorySitemap = (node: UnitySitemap): node is SitemapCategory =>
    node.type === 'category';

export const isUnityInternalLinkSitemap = (node: UnitySitemap): node is SitemapInternalLink =>
    node.type === 'internal';

const MenuNodeBase = Entity.extend({
    availableFor: z
        .object({
            app: z.boolean(),
            desktop: z.boolean(),
        })
        .optional(),
    highlighted: z.boolean().optional(),
    icon: z
        .object({
            elvisId: z.string().nullish(),
            url: z.string().nullish(),
        })
        .nullish(),
    sitemap: z
        .object({
            id: z.number().optional(),
            name: z.string().optional(),
        })
        .optional(),
});

type MenuNodeBase = z.infer<typeof MenuNodeBase>;

type CompleteMenuNode = MenuNodeBase & {
    children?: CompleteMenuNode[];
};
const MenuNode: z.ZodType<CompleteMenuNode> = MenuNodeBase.extend({
    children: z.lazy(() => z.array(MenuNode).optional()),
});

export type MenuNode = z.infer<typeof MenuNode>;

export const Menu = Entity.extend({
    id: z.number(),
    version: z.number().optional(),
    type: z.string().optional(),
    tenantId: z.number().optional(),
    nodes: z.array(MenuNode).optional(),
});

export type Menu = z.infer<typeof Menu>;

export const CollectionItem = Entity.extend({
    type: z.literal('brixwireKeyword'),
    categoryIds: z.array(z.number()),
    keyword: z.string(),
    version: z.number(),
    id: z.number(),
    createdBy: z.string(),
    lastUpdatedBy: z.string(),
});

export type CollectionItem = z.infer<typeof CollectionItem>;

export const PageStyle = z.object({
    name: z.string(),
    tenantIds: z.array(z.number()),
    types: z.array(z.string()),
});

export type PageStyle = z.infer<typeof PageStyle>;

const CategoryBase = Entity.extend({
    id: z.number(),
    active: z.boolean(),
    expiryDate: z.string().nullable(),
    frontCategory: z.boolean().optional(),
    name: z.string(),
    parentId: z.number().nullable(),
    parentName: z.string().nullable(),
    temporary: z.boolean().optional(),
    type: z.string(),
    syncedWithDesknet: z.boolean(),
});

type CategoryBase = z.infer<typeof CategoryBase>;

export type CompleteCategory = CategoryBase & {
    children: CompleteCategory[];
};

export const Category: z.ZodType<CompleteCategory> = CategoryBase.extend({
    children: z.lazy(() => z.array(Category)),
});

export type Category = z.infer<typeof Category>;

type CompleteUnityCategory = CategoryBase & {
    subCategories: CompleteUnityCategory[];
};
export const UnityCategory: z.ZodType<CompleteUnityCategory> = CategoryBase.extend({
    subCategories: z.lazy(() => z.array(UnityCategory)),
});

export type UnityCategory = z.infer<typeof UnityCategory>;

const NewCategoryBase = CategoryBase.omit({ id: true });

type NewCategoryBase = z.infer<typeof NewCategoryBase>;

type NewCompleteCategory = NewCategoryBase & {
    children: NewCompleteCategory[];
};

export const NewCategory: z.ZodType<NewCompleteCategory> = NewCategoryBase.extend({
    children: z.lazy(() => z.array(NewCategory)),
});
export type NewCategory = z.infer<typeof NewCategory>;

type UnityCompleteCategory = NewCategoryBase & {
    subCategories: UnityCompleteCategory[];
};

export const NewUnityCategory: z.ZodType<UnityCompleteCategory> = NewCategoryBase.extend({
    subCategories: z.lazy(() => z.array(NewUnityCategory)),
});

export type NewUnityCategory = z.infer<typeof NewUnityCategory>;

export const Tenant = z.object({
    audienceType: z.nativeEnum(AUDIENCE_TYPE),
    categoriesRequired: z.boolean().optional(),
    contentStationTenantName: z.string().nullable(),
    id: z.number(),
    language: z.union([z.literal('de_CH'), z.literal('fr_CH')]),
    logoUrl: z.string(),
    name: z.string(),
    primaryColor: z.string(),
    streamboatTenantName: z.string(),
});

export type Tenant = z.infer<typeof Tenant>;

export const TenantGroup = z.object({
    id: z.number(),
    name: z.string(),
    logoUrl: z.string(),
    language: z.string(),
    tenantIds: z.array(z.number()),
});

export type TenantGroup = z.infer<typeof TenantGroup>;

export const Locale = z.object({
    locale: z.enum([LANG_CODES.GERMAN, LANG_CODES.FRENCH]),
});

export type Locale = z.infer<typeof Locale>;
