import { capitalize, toCamelCase } from 'remeda';

import type ContentStatus from '@@constants/ContentStatus';

export const getColorName = (status: ContentStatus) => `contentStatus.${toCamelCase(status)}`;

export const getI18nKey = (status: ContentStatus) => `contentStatus.${status}`;

export const getIconName = (status: ContentStatus) =>
    `contentStatus${capitalize(toCamelCase(status))}`;
