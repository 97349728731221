import { createSlice } from '@reduxjs/toolkit';
import { prop } from 'remeda';

import { type RootState } from '@@store/store';

import { ROLES, type UserRole } from './constants';
import checkAuthentication from './utils/checkAuthentication';
import parseJWT from './utils/parseJWT';

const MS = 1000;

export type AuthState = {
    tokenType: string | null;
    accessToken: string | null;
    jti: string | null;
    userId: string | null;
    userRole: ValueOf<typeof ROLES> | null;
    expiresIn: number;
    loggedInTime: number;
};

const initialState: AuthState = {
    tokenType: null,
    accessToken: null,
    jti: null,
    userId: null,
    userRole: null,
    expiresIn: 0,
    loggedInTime: 0,
};

export const chooseRole = (role?: UserRole) => {
    if (role && Object.values(ROLES).includes(role)) {
        return role;
    }

    if (role) {
        console.warn('invalid user role supplied', role);
    }

    return ROLES.EDITOR;
};

/* We disable the camelcase rule because these are defined outside of our app */
/* eslint-disable camelcase */
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        processAuthenticationPayload(state, action) {
            // TODO:
            // next line comes from location after the redirect, it also contains jti
            // it's kinda weird as everything is passes using the url,.. the token alone
            // carries all information.
            const { access_token, token_type, expires_in } = action.payload;

            const tokenData = parseJWT(access_token);

            const userState = (
                tokenData
                    ? {
                          // We do not add any other user properties (e.g. user_name, first_name, ...) here,
                          // since we prefer one source of truth. So we fetch the user entity for that.
                          userId: tokenData.unity_user_id || null,
                          userRole: chooseRole(
                              tokenData.authorities && prop(tokenData.authorities, 0),
                          ),
                      }
                    : {}
            ) satisfies Partial<AuthState>;

            return {
                ...initialState,
                tokenType: token_type,
                accessToken: access_token,
                // Work with milliseconds everywhere!!!!
                expiresIn: Number(expires_in) * MS,
                loggedInTime: Date.now(),
                ...userState,
            };
        },
        clearAuthentication() {
            return { ...initialState };
        },
    },
    selectors: {
        getExpiresIn: (state) => state.expiresIn,
        getLoggedInTime: (state) => state.loggedInTime,
        getAuthToken: (state) =>
            state.accessToken && state.tokenType ? state.tokenType + ' ' + state.accessToken : null,
        getAuth: (state) => state,
        getUserRole: (state) => state.userRole,
        getUserId: (state) => state.userId,
        getAccessToken: (state) => state.accessToken,
        selectIsAuthenticated: (state) => checkAuthentication(state.loggedInTime, state.expiresIn),
    },
});
/* eslint-enable camelcase */

export const selectIsAuthenticated = (state: RootState) =>
    checkAuthentication(getLoggedInTime(state), getExpiresIn(state));

export const { processAuthenticationPayload, clearAuthentication } = authSlice.actions;
export const {
    getExpiresIn,
    getLoggedInTime,
    getAuthToken,
    getAuth,
    getUserRole,
    getUserId,
    getAccessToken,
} = authSlice.selectors;
export default authSlice.reducer;
