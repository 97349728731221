import { styled } from '@mui/material';
import { forwardRef } from 'react';
import { toKebabCase } from 'remeda';

import { resolveColor } from '@@scripts/themes/mui/utils';

export const ICON_SIZES = {
    XSMALL: 'xsmall',
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    XLARGE: 'xlarge',
    XXLARGE: 'xxlarge',
} as const;

export type IconSize = ValueOf<typeof ICON_SIZES> | string;

export type Props = {
    name: string;
    size?: IconSize;
    title?: string;
    color?: 'primary' | 'secondary' | 'error' | 'success' | 'warning' | string;
};

const Svg = styled('svg')<{ $color?: Props['color']; $size: IconSize }>(
    ({ $color, $size, theme }) => ({
        height: theme.fixed.icon[$size] || $size,
        display: 'inline-block',
        fill: 'currentColor',
        ...($color && { color: resolveColor(theme, $color) }),
    }),
);

const Icon = forwardRef<SVGSVGElement, Props>(
    ({ color, name, size = 'medium', title, ...rest }, ref) => (
        <Svg
            {...rest}
            ref={ref}
            $color={color}
            $size={size}
            viewBox="0 0 1 1"
            role="img"
            aria-hidden="true"
        >
            {title ? <title>{title}</title> : null}

            <use href={`#icon-${toKebabCase(name)}`} />
        </Svg>
    ),
);

export default styled(Icon)({});
