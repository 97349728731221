import { type AppRoute } from '@ts-rest/core';
import { mapValues } from 'remeda';

import { type RequestQuery } from '@@api/types';

export const getQueryParams = <
    T extends AppRoute,
    U extends { [K in keyof RequestQuery<T>]: any } = { [K in keyof RequestQuery<T>]: any },
>(
    queryParams: U,
    separators?: { [K in keyof RequestQuery<T>]?: ',' | '|' },
): RequestQuery<T> => {
    const newValues = mapValues(queryParams, (value, key) => {
        if (Array.isArray(value)) {
            if (value.length === 0) {
                return undefined;
            }

            const separator = separators?.[key] || ',';

            return value.join(separator);
        }

        if (value === '' || value === null) {
            return undefined;
        }

        return value;
    }) satisfies RequestQuery<T>;

    return newValues as RequestQuery<T>;
};
