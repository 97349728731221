import { type ComponentType } from 'react';
import { doNothing } from 'remeda';

import Deferred from './Deferred';

export const not =
    (fn) =>
    (...args) =>
        !fn(...args);

type ComponentEnhancer<TInner, TOutter> = (
    component: ComponentType<TInner>,
) => ComponentType<TOutter>;

// Stolen from recompose npm-package
export const compose = <TInner, TOutter>(...funcs): ComponentEnhancer<TInner, TOutter> =>
    funcs.reduce(
        (a, b) =>
            (...args) =>
                a(b(...args)),
        (arg) => arg,
    );

export type AsyncDebounceOptions<Args extends any[]> = {
    debounceDelay?: number;
    onFirstCall?: (...args: Args) => void;
};

export const asyncDebounce = <T, Args extends any[] = any[]>(
    asyncFunction: (...args: Args) => Promise<T>,
    options: AsyncDebounceOptions<Args> = {},
) => {
    const internalOptions = { debounceDelay: 0, onFirstCall: doNothing(), ...options };
    const deferreds: Deferred<T>[] = [];

    let prevTimeoutId = 0;

    const returnFunction = (...args: Args) => {
        // Remove any existing deferreds. We will reject all of them further down and therefore
        // we do not need them anymore
        const oldDeferreds = deferreds.splice(0);
        const deferred = new Deferred<T>();

        // Cancel the previous timeout if it is still active. The current call becomes the master now
        if (prevTimeoutId) {
            window.clearTimeout(prevTimeoutId);
        }

        deferreds.push(deferred);

        if (oldDeferreds.length === 0) {
            // If there are no pending function calls, this is considered the first call of this "debounce phase"
            internalOptions.onFirstCall(...args);
        } else {
            // Cancel all pending function calls. The current call becomes the master now
            oldDeferreds.forEach(({ reject }) => {
                reject('canceled');
            });
        }

        // Wait for `internalOptions.debounceDelay` before executing the debounced function
        const timeoutId = (prevTimeoutId = window.setTimeout(() => {
            // If no new calls to the debounced function were made whilte waiting for the timeout,
            // we can reset `prevTimeoutId` since no timeout is being executed anymore
            if (timeoutId === prevTimeoutId) {
                prevTimeoutId = 0;
            }

            asyncFunction(...args)
                .then((value) => {
                    // If new calls to the debounced function were made while waiting for the timeout and the
                    // async function to become fulfilled, this `deferred` would have been already rejected.
                    // But if it is still pending, it means we did not receive new calls to the debounced function
                    // and we can resolve it now
                    if (deferred.state === 'pending') {
                        deferred.resolve(value);

                        deferreds.length = 0;
                    }
                })
                .catch((reason) => {
                    // If new calls to the debounced function were made while waiting for the timeout and the
                    // async function to become rejected, this `deferred` would have been already rejected.
                    // But if it is still pending, it means we did not receive new calls to the debounced function
                    // and we can reject it now
                    if (deferred.state === 'pending') {
                        deferred.reject(reason);

                        deferreds.length = 0;
                    }
                });
        }, internalOptions.debounceDelay));

        return deferred.promise;
    };

    // Provide a way to change the debounce delay from the outside
    returnFunction.setDebounceDelay = (
        debounceDelay: AsyncDebounceOptions<Args>['debounceDelay'] = 0,
    ) => {
        internalOptions.debounceDelay = debounceDelay;
    };

    return returnFunction;
};
