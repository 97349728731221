import { Skeleton, styled } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { pick } from 'remeda';

import { PLACEHOLDER_IMAGE_ASPECT_RATIO } from '@@editor/constants';
import placeholderImage from '@@images/placeholder-image.svg';
import { decodeImage } from '@@scripts/utils';

export type Props = {
    src?: string | null;
    previewSrc?: string | null;
    placeholder?: string;
    alt?: string;
    className?: string;
    skeleton?: boolean;
    title?: string;
    onClick?: VoidFunction;
    onLoad?: (image: HTMLImageElement) => void;
    onError?: VoidFunction;
};

type UsePreloadImageProps = Pick<Props, 'src' | 'onLoad' | 'onError'>;

const usePreloadImage = ({ src: inputSrc, onLoad, onError }: UsePreloadImageProps) => {
    const [error, setError] = useState(false);
    const [src, setSrc] = useState<string | null>(null);

    const handleLoad = (e) => {
        setError(false);
        onLoad?.(e);
    };

    const handleError = () => {
        setError(true);
        onError?.();
    };

    useEffect(() => {
        setSrc(null);
        setError(false);

        if (inputSrc) {
            const image = new window.Image();
            image.src = inputSrc;
            decodeImage(image)
                .then(() => {
                    setSrc(inputSrc);
                    handleLoad(image);
                })
                .catch(() => {
                    handleError();
                });
        }
    }, [inputSrc]);

    // It is important to already get the correct loading state on first render, in order to avoid flickering
    // of the image. Therefore, we cannot use an `isLoading` react state here, because it would not be `true`
    // immediately, whenever `inputSrc` changes.
    const isLoading = Boolean(inputSrc && inputSrc !== src && !error);

    return { isLoading, error };
};

const Image = forwardRef<HTMLImageElement, Props>(
    (
        {
            src,
            previewSrc,
            placeholder = placeholderImage,
            skeleton = false,
            onLoad,
            onError,
            ...restProps
        },
        ref,
    ) => {
        const { isLoading: isLoadingSrc, error } = usePreloadImage({
            src,
            onLoad,
            onError,
        });

        const { isLoading: isLoadingPreviewSrc } = usePreloadImage({
            src: previewSrc,
        });

        const isPlaceholder = Boolean(error || (!src && !previewSrc));
        const isPreview = Boolean(!isPlaceholder && previewSrc && (!src || isLoadingSrc));
        // In order to avoid too much jumping, we will not render the skeleton while the preview image is loading.
        // As a consequence, the previous image will be visible until the new image is loaded. Therefore, we hide the
        // image element while the preview image is still loading (further down)
        const isSkeleton = Boolean(isLoadingSrc && !previewSrc && skeleton);

        if (isSkeleton) {
            return (
                <Skeleton
                    role="progressbar"
                    variant="rectangular"
                    animation="wave"
                    style={{
                        aspectRatio: PLACEHOLDER_IMAGE_ASPECT_RATIO,
                        objectFit: 'cover',
                        objectPosition: 'initial',
                        width: '100%',
                        height: 'auto',
                    }}
                />
            );
        }

        const imgProps = pick(restProps, ['alt', 'className', 'title', 'onClick']);

        if (isPlaceholder) {
            Object.assign(imgProps, {
                src: placeholder,
                style: {
                    objectFit: 'cover',
                    objectPosition: 'initial',
                },
                // This is needed for letting `PreviewImage` know that `Image` currently renders a placeholder image
                'data-placeholder': true,
            });
        } else if (isPreview) {
            Object.assign(imgProps, {
                src: previewSrc,
                // If the preview image is still loading, we hide the image element to avoid flickering (the
                // previous rendered preview image would be visible for a short moment / as long as the new
                // preview image is loading)
                ...(isLoadingPreviewSrc && {
                    style: {
                        visibility: 'hidden',
                    },
                }),
            });
        } else {
            Object.assign(imgProps, {
                // Although the image has not been loaded yet, we render an image element with the according image url, in
                // order to make it look like a normal native browser image loading.
                src: src ?? '',
            });
        }

        // It is important to always render the same img element, in order to avoid flickering
        return <img {...imgProps} ref={ref} />;
    },
);

export default styled(Image)({});
