import { Button, styled } from '@mui/material';
import { type ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { omit } from 'remeda';

import { useAiToolsClient } from '@@api/services/aiTools/client';
import { type Image as ImageType } from '@@api/utils/schemas/schemas';
import Icon from '@@components/Icon';
import InfoTooltip from '@@components/InfoTooltip';
import Spacer from '@@components/Spacer';
import useReactHookFormContext from '@@containers/ReactHookForm/useReactHookFormContext';
import { NameSource } from '@@editor/helpers/Element';
import makeState from '@@editor/utils/makeState';
import { type ButtonsType } from '@@form/components/ImageEditor/ImageUpload/ButtonsContext';
import { type ImageUploadProps } from '@@form/components/ImageEditor/ImageUpload/ImageUpload';
import TextareaField from '@@form/fields/Textarea';
import maxLength from '@@form/utils/validators/maxLength';
import { sanitizeHtml } from '@@utils/string';

import ImageUploadField from './ImageUploadField';
import RadioGroupField from './RadioGroup';
import CaptionEditorField from './RichTextEditor/CaptionEditor';
// If we remove that import, even though we do not need it here anymore, we will get
// a circular dependency error:
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import RichTextStringField from './RichTextEditor/RichTextString';
import TextField from './TextInput';

const MAX_DESCRIPTION_LENGTH = 1000;
const IMAGE_METADATA_FIELDS = ['caption', 'credit'];
const isRTEDataFormat = (fieldName) => ['caption'].includes(fieldName);

const RefreshGroup = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

const DescriptionLabel = styled('div')({
    display: 'flex',
});

export const IMAGE_STYLE_TYPES = {
    image: 'image',
    infographic: 'infographic',
};

type BaseProps = {
    name: string;
    allowImageEdit?: boolean;
    aspectRatio?: string;
    defaultValue?: ImageType;
    isGeneratingDescription?: boolean;
    label?: string;
    flagAsOptional?: boolean;
    noDropArea?: boolean;
    hasButtonGroup?: boolean;
    placeholder?: string;
    required?: boolean;
    tabIndex?: number;
    requiredFields?: {
        name?: boolean;
        credit?: boolean;
    };
    renderButtons?: (buttons: ButtonsType, ownProps: ImageUploadProps) => ReactNode;
    showCaption?: boolean;
    showCredit?: boolean;
    showImageType?: boolean;
    transformations?: {
        maxWidth: number;
        maxHeight: number;
    };
};

type ImageOnlyProps = BaseProps & {
    imageOnly: true;
    imageAndDescriptionOnly?: never;
};

type DescriptionOnlyProps = BaseProps & {
    imageOnly?: never;
    imageAndDescriptionOnly?: true;
};

type Props = ImageOnlyProps | DescriptionOnlyProps;

const TextFields = (
    props: Props & {
        isLoadingImageDescription?: boolean;
        loadImageDescription: (elvisId: string) => void;
    },
) => {
    const {
        defaultValue,
        imageAndDescriptionOnly,
        name,
        requiredFields = {
            name: false,
            credit: false,
        },
        showCaption = true,
        showCredit = true,
        isLoadingImageDescription,
        loadImageDescription,
    } = props;
    const { t } = useTranslation();
    const { setValue, getValues } = useReactHookFormContext();
    const imageDataFieldName = name.split('.').pop();
    const imageData = imageDataFieldName && getValues(name)?.[imageDataFieldName];

    const handleRefreshImageDescription = () => {
        if (imageData?.elvisId) {
            loadImageDescription(imageData.elvisId);
        }
    };

    return (
        <>
            <Spacer md v />

            <TextareaField
                name={`${name}.name`}
                label={<DescriptionLabel>{t('image.description')}</DescriptionLabel>}
                defaultValue={defaultValue?.name}
                validate={[maxLength(MAX_DESCRIPTION_LENGTH)]}
                disabled={isLoadingImageDescription}
                onChange={(e, onChange) => {
                    onChange(e);

                    setValue(`${name}.nameSource`, NameSource.UNITY);
                }}
            />

            <RefreshGroup>
                <InfoTooltip
                    title={t('metaform.teaser.imageDescription.tooltip')}
                    iconName="bernand"
                    iconSize="large"
                    iconColor="amber"
                >
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<Icon name="rotate" />}
                        loading={isLoadingImageDescription}
                        disabled={isLoadingImageDescription || !imageData?.url}
                        onClick={handleRefreshImageDescription}
                    >
                        {t('metaform.teaser.modal.refreshDescription')}
                    </Button>
                </InfoTooltip>
            </RefreshGroup>

            <Spacer md v />

            {!imageAndDescriptionOnly && (
                <>
                    {showCaption && (
                        <CaptionEditorField
                            name={`${name}.caption`}
                            label={t('image.caption')}
                            defaultValue={defaultValue?.caption}
                            displayUsedCharacters
                        />
                    )}

                    <Spacer md v />

                    {showCredit && (
                        <TextField
                            name={`${name}.credit`}
                            label={t('image.credit')}
                            defaultValue={defaultValue?.credit}
                            required={requiredFields.credit}
                            displayUsedCharacters
                        />
                    )}
                </>
            )}
        </>
    );
};

export const ImageField = (props: Props) => {
    const {
        defaultValue,
        imageOnly,
        isGeneratingDescription,
        imageAndDescriptionOnly,
        name,
        showImageType = false,
    } = props;
    const { t } = useTranslation();
    const { setValue, getValues } = useReactHookFormContext();

    const { client: aiToolsClient } = useAiToolsClient();
    const { mutate: postResource, isPending: isSubmitting } =
        aiToolsClient.imageAltTextGenerator.post.useMutation();

    const handleImageMetadataChange = useCallback(
        (metadata) =>
            IMAGE_METADATA_FIELDS.forEach((fieldName) => {
                const fieldHasMetadata = metadata.hasOwnProperty(fieldName);

                if (fieldHasMetadata) {
                    const fieldPath = `${name}.${fieldName}`;

                    const sanitizedValue = sanitizeHtml(metadata[fieldName]);

                    setValue(
                        fieldPath,
                        isRTEDataFormat(fieldName) ? makeState(sanitizedValue) : sanitizedValue,
                    );
                }
            }),
        [setValue, getValues, name],
    );

    const loadImageDescription = (elvisId) => {
        postResource(
            {
                body: {
                    elvisId,
                },
            },
            {
                onSuccess: ({ body }) => {
                    setValue(`${name}.name`, body.altText);
                    setValue(`${name}.nameSource`, NameSource.AI);
                },
            },
        );
    };

    const handleImageUploadChange = (value) => {
        if (value.elvisId) {
            loadImageDescription(value.elvisId);
        }
    };

    const getUploadImageFieldName = () => {
        if (imageOnly) {
            return name;
        }

        // in case name is nested (for example variants.0.image)
        const path = name.split('.');

        return `${name}.${path[path.length - 1]}`;
    };

    return (
        <>
            <ImageUploadField
                {...props}
                onChange={(value, fieldOnChange) => {
                    fieldOnChange(omit(value, ['uploadSuccess']));

                    if (imageAndDescriptionOnly || !imageOnly) {
                        if (value.uploadSuccess) {
                            handleImageUploadChange(value);
                        }
                    }
                }}
                name={getUploadImageFieldName()}
                defaultValue={!imageOnly ? {} : defaultValue}
                onMetadataChange={handleImageMetadataChange}
            />

            {showImageType && (
                <>
                    <Spacer md v />

                    <RadioGroupField
                        row
                        required
                        name={`${name}.style.imageType`}
                        defaultValue={defaultValue?.style?.imageType}
                        fields={[
                            {
                                value: IMAGE_STYLE_TYPES.image,
                                label: t('image.style.image'),
                            },
                            {
                                value: IMAGE_STYLE_TYPES.infographic,
                                label: t('image.style.infographic'),
                            },
                        ]}
                    />

                    <Spacer md v />
                </>
            )}

            {(!imageOnly || imageAndDescriptionOnly) && (
                <TextFields
                    {...props}
                    loadImageDescription={loadImageDescription}
                    isLoadingImageDescription={isSubmitting || isGeneratingDescription}
                />
            )}
        </>
    );
};

export default ImageField;
