import { capitalize, toCamelCase } from 'remeda';

import type PublicationStatus from '@@constants/PublicationStatus';

export const getColorName = (status: PublicationStatus) =>
    `publicationStatus.${toCamelCase(status)}`;

export const getI18nKey = (status: PublicationStatus) => `publicationStatus.${status}`;

export const getIconName = (status: PublicationStatus) =>
    `publicationStatus${capitalize(toCamelCase(status))}`;
