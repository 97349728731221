import { type ElementType, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { partialBind } from 'remeda';

import Deferred from '@@utils/Deferred';

import Modal from '../Modal';
import ModalActionsContext from './ModalActionsContext';
import { type ModalProps } from '../types';

export type Props<T extends ModalProps> = PartialBy<T, 'onClose'>;

export type Options = {
    component?: ElementType;
    dependencies?: any[];
};

export type UseModalResult = {
    modalId: string;
    showModal: (props?: AnyObject) => void;
    hideModal: () => void;
    confirm: (props?: AnyObject) => Promise<void>;
    delete: (props?: AnyObject) => Promise<void>;
};

export const useModal = <T extends ModalProps>(
    // We are defining `onClose` in `ModalProvider`, so it can be optional when using with `useModal`
    props: Props<T>,
    options: Options = {},
): UseModalResult => {
    const { component = Modal, dependencies = [] } = options;
    const actions = useContext(ModalActionsContext);
    const [modalId] = useState(() => crypto.randomUUID());
    const confirmDeferred = useRef<Deferred | null>(null);
    const deleteDeferred = useRef<Deferred | null>(null);
    const modalProps = {
        ...props,
        onClose: () => {
            confirmDeferred.current?.reject();
            deleteDeferred.current?.reject();
            confirmDeferred.current = null;
            deleteDeferred.current = null;

            props.onClose?.();
        },
        onConfirm: () => {
            confirmDeferred.current?.resolve(undefined);
            confirmDeferred.current = null;

            props.onConfirm?.();
        },
        onDelete: () => {
            deleteDeferred.current?.resolve(undefined);
            deleteDeferred.current = null;

            props.onDelete?.();
        },
    };

    useEffect(() => {
        actions.updateProps(modalId, modalProps);
    }, dependencies);

    useEffect(() => {
        actions.register(modalId, modalProps, component);

        return () => actions.unregister(modalId);
    }, []);

    const showModal = useMemo(() => partialBind(actions.show, modalId), [actions, modalId]);
    const hideModal = useMemo(() => partialBind(actions.hide, modalId), [actions, modalId]);

    return useMemo(
        () => ({
            modalId,
            showModal,
            hideModal,
            confirm: (props?: AnyObject) => {
                showModal(props);

                confirmDeferred.current = new Deferred();

                return confirmDeferred.current.promise.then(hideModal);
            },
            delete: (props?: AnyObject) => {
                showModal(props);

                deleteDeferred.current = new Deferred();

                return deleteDeferred.current.promise.then(hideModal);
            },
        }),
        [modalId, showModal, hideModal],
    );
};

export default useModal;
